<template>
  <Modal
    ref="modal"
    :bg="false"
    overlay="light"
    :non-closable="nonClosable"
    no-click-away
    :no-fade-in="noFadeIn"
    @close="$emit('close')"
    @hide="$emit('hide')"
    @show="$emit('show')"
  >
    <div style="margin: 1rem">
      <AppNavButton
        v-if="!nonClosable"
        icon="close"
        style="position: fixed; top: 1rem; right: 1rem"
        @click="() => $emit('close') && $refs.modal.hide()"
      />
      <div
        style="
          display: grid;
          gap: 1rem;
          justify-items: center;
          align-content: center;
          text-align: center;
          padding: 0 2rem;
        "
        class="container-xs"
      >
        <img
          v-if="loader === 'window'"
          src="@/assets/LoadingWindow2.gif"
          alt="searching"
        />
        <img
          v-if="loader === 'plane'"
          src="@/assets/BookingSuccess.gif"
          alt="searching"
        />
        <p
          v-if="text"
          style="
            min-height: calc(15px * 1.5 * 3);
            min-height: 100px;
            line-height: 1.5;
            font-size: 15px;
            font-family: LuciferSans;
            text-transform: uppercase;
            color: #333;
          "
          :class="{ 'loading-ellipsis': false }"
        >
          {{ text }}
        </p>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../../components/utils/Modal.vue'
import AppNavButton from '../AppNavButton.vue'

export default {
  name: 'LoadingModalFullScreen',
  components: { Modal, AppNavButton },
  props: {
    text: String,
    nonClosable: Boolean,
    noFadeIn: Boolean,
    loader: {
      type: String,
      default: 'window',
      validator: value => ['window', 'plane'].includes(value),
    },
  },
  methods: {
    show() {
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    },
  },
}
</script>

<style scoped lang="scss"></style>
