<template>
  <LoadingModalFullScreen
    ref="modal"
    no-fade-in
    non-closable
    text="Finalizing registration"
  />
</template>

<script>
import LoadingModalFullScreen from '../../components-rf/modals/LoadingModalFullScreen.vue'
export default {
  name: 'ConfirmAccount',
  mounted() {
    this.$refs.modal.show()
    const token = this.$route.query.token
    this.$store
      .dispatch('AuthModule/verifyEmail', { token })
      // .then(() => this.$router.push('/login'))
      .catch(() => this.$router.push('/'))
  },
  components: { LoadingModalFullScreen },
}
</script>

<style scoped lang="scss"></style>
